import {
  controlEmailValidation,
  controlSubmitButton,
  hideEmailValidation,
  inputValidationError,
} from '../packs/validation_handler';

let isEmailValid = true;
let passwordNotEmpty = false;
const emailRegex =
  /^([a-zA-Z0-9+_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const emailNotFoundErrorMessage =
  'This email is not associated with any account';

function emailValidation() {
  const email = $('#user_email').val();
  isEmailValid = !!emailRegex.test(email);
}

function passwordFieldNotEmpty() {
  passwordNotEmpty = !!$('#user_password').val();
}

function handleAutoFill() {
  setTimeout(function() {
    $('input:-webkit-autofill').length === 2 
      ? controlSubmitButton('#submit-button', false)
      : controlSubmitButton('#submit-button', true);
  }, 333)
}

window.activateSubmitButton = function (loginPage, wrongEmailFormatText) {
  handleAutoFill();
  $('#user_email').on('focusout', function () {
    controlEmailValidation(wrongEmailFormatText, isEmailValid, '#user_email');
  });
  $('#user_email').on('input', function (e) {
    hideEmailValidation(wrongEmailFormatText);
  });
  $('#user_password, #user_email').on('input', function (e) {
    emailValidation();
    if (loginPage) {
      passwordFieldNotEmpty();
      isEmailValid && passwordNotEmpty
        ? controlSubmitButton('#submit-button', false)
        : controlSubmitButton('#submit-button', true);
    } else {
      isEmailValid
        ? controlSubmitButton('#submit-button', false)
        : controlSubmitButton('#submit-button', true);
    }
  });
};

function checkCorrect2faFormat() {
  $('#otp-result').val().length === 6
    ? controlSubmitButton('.two-factor__button', false)
    : controlSubmitButton('.two-factor__button', true);
}

function handleWrongCode(inputSelector, validationErrorSelector) {
  if ($('.alert-dismissible').length) {
    $('.alert-dismissible').remove();
    $(inputSelector).addClass('invalid');
    $(validationErrorSelector).css('visibility', 'visible');
  }
}

function hideWrongCodeInfo(inputSelector, validationErrorSelector) {
  $(inputSelector).removeClass('invalid');
  $(validationErrorSelector).css('visibility', 'hidden');
}

function handleKeypress(event) {
  $('.two-factor__input--otp').on(event, function (e) {
    if (this.value.length === 1 && e.key !== 'Backspace' && !!this.value.trim()) {
      $(this).next('.two-factor__input--otp').trigger('focus');
    }
  });
}

function handleBackspace() {
  $('.two-factor__input--otp').on('keydown', function (e) {
    if (e.key === 'Backspace' && this.value.length === 0) {
      $(this).prev('.two-factor__input--otp').trigger('focus');
    }
  });
}

function openRecoveryCodeView(recoveryCodeMode) {
  $('.otp-wrapper').hide();
  $('.recovery-code-wrapper').show();
}

function handlePastingInto2faInput() {
  document.addEventListener("paste", function(e) {
    let copiedCode = e.clipboardData.getData('Text');
    if (copiedCode.length === 6 && !!copiedCode.trim()) {
      copiedCode = copiedCode.split('');
      $('.two-factor__input--otp').each(function(i) { this.value = copiedCode[i] });
      $('#otp-input-6').trigger('focus');
    } else {
      $('#otp-input-1').val('');
      $('#otp-input-1').trigger('focus');
    }
  });
}

window.twoFactorInputsHandler = function () {
  handleWrongCode('.two-factor__input--otp', '.two-factor__text--invalid-code');
  $('.two-factor__input--otp').on('input', function (e) {
    let otpCode = '';
    $('input[id^="otp-input-"]').each(function () {
      otpCode += this.value;
    });
    $('#otp-result').val(otpCode);
    checkCorrect2faFormat();
    hideWrongCodeInfo(
      '.two-factor__input--otp',
      '.two-factor__text--invalid-code'
    );
  });
  handlePastingInto2faInput();
  handleBackspace();
  handleKeypress('input');
  handleKeypress('keydown');
};

window.useRecoveryCode = function (recoveryCodeMode) {
  $('.two-factor__span--recovery-code').click(function () {
    openRecoveryCodeView(recoveryCodeMode);
  });
};

window.recoveryCodeHandler = function (recoveryCodeMode) {
  if (recoveryCodeMode) {
    handleWrongCode(
      '#user_otp_attempt',
      '.recovery-code-wrapper__span--wrong_input'
    );
    $('.recovery-code-wrapper').show();
  }
  $('#user_otp_attempt').on('input', function (e) {
    hideWrongCodeInfo(
      '#user_otp_attempt',
      '.recovery-code-wrapper__span--wrong_input'
    );
    $('#user_otp_attempt').val().length >= 8
      ? controlSubmitButton('.recovery-code-wrapper__button', false)
      : controlSubmitButton('.recovery-code-wrapper__button', true);
  });
};

window.resetPasswordHandler = function (errorMessage) {
  if (errorMessage === 'not found')
    inputValidationError(
      '.password_reset__input--email',
      '.password_reset__span--wrong_input',
      emailNotFoundErrorMessage
    );
};
