window.changeFontSize = function () {
  const textArea = $('#watermark_text');
  let textLines = textArea.val().split('\n');
  const maxTextLength = 15.0; // the maximum length of one line of text in vw unit
  const defaultFontSize = 1.79; // the font size before any scaling in vw unit
  const maxTextHeight = 3.8; // maximum height of five lines of text which can fit into watermark, in vw unit

  function isWatermarkTooWide(array) {
    return array.some((x) => x.length * defaultFontSize >= maxTextLength);
  }

  function isWatermarkTooHigh(array) {
    return array.length * defaultFontSize >= maxTextHeight;
  }

  function centerText() {
    heightValue = textArea.css('height');
    height = parseInt(heightValue.split('px')[0]);
    fontSizeValue = textArea.css('font-size');
    fontSize = parseInt(fontSizeValue.split('px')[0]);
    lines = $('#watermark_text').val().split('\n').length;
    padding = (height - 3 * (fontSize * lines)) / 4;
    // to count proper padding size we have to substract the size of all lines of text in watermark from the total length
    // of text area and then divide it by 4 so that we get the value for both top and bottom padding of the area
    textArea.css({ padding: padding + 'px 0' });
  }

  function widthScaling() {
    textLines = textArea.val().split('\n');
    textLengthArray = textLines.map(function (x) {
      return x.length;
    });
    maxLength = Math.max.apply(null, textLengthArray);
    properFontSize = (maxTextLength / maxLength).toPrecision(4);
    return properFontSize;
  }

  function heightScaling(text) {
    linesAmount = text.length;
    maxFontSize = (maxTextHeight / linesAmount).toPrecision(4);
    return maxFontSize;
  }

  function scaleToFit(tooWide, tooHigh, text) {
    if (tooHigh && tooWide) {
      newSize = Math.min(widthScaling(), heightScaling(text));
      textArea.css({ 'font-size': newSize + 'vw' });
      centerText();
    } else if (tooWide) {
      textArea.css({ 'font-size': widthScaling() + 'vw' });
      centerText();
    } else if (tooHigh) {
      textArea.css({ 'font-size': heightScaling(text) + 'vw' });
      centerText();
    } else {
      textArea.css({ 'font-size': '1.79vw' });
      centerText();
    }
  }

  scaleToFit(
    isWatermarkTooWide(textLines),
    isWatermarkTooHigh(textLines),
    textLines
  );

  textArea.bind('change keyup', function (event) {
    const maxLinesAmount = 5;
    const textLines = $(this).val().split('\n');
    const content = $(this).val();
    const value = '';

    $('#watermark_text').keydown(function (e) {
      newLines = $(this).val().split('\n').length;
      if (e.keyCode === 13 && newLines >= maxLinesAmount) {
        return false;
      }
    });
    scaleToFit(
      isWatermarkTooWide(textLines),
      isWatermarkTooHigh(textLines),
      textLines
    );
  });
};
