import HidePasswordImage from '../images/hide-password.png';
import RevealPasswordImage from '../images/reveal-password.png';

let isHidden = true;

window.togglePassword = function (eyeId, fieldId) {
  $(eyeId).on('click', function () {
    if (isHidden === true) {
      isHidden = false;
      $(eyeId).attr('src', HidePasswordImage);
    } else {
      isHidden = true;
      $(eyeId).attr('src', RevealPasswordImage);
    }
    const password = document.querySelector(fieldId);
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
  });
};
