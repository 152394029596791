const emailWrongFormatErrorMessage = 'Wrong email format';
const green = 'rgba(40, 167, 69, 1)';
const grey = 'rgba(202, 202, 202, 1)';
const red = 'rgba(220, 53, 69, 1)';

const passwordValidationRules = {
  uppercaseValidated: false,
  lenghtValidated: false,
  lowercaseValidated: false,
  digitValidated: false,
  specialCharacterValidated: false,
};

export function hideEmailValidation(wrongEmailFormatText) {
  $('#user_email').removeClass('invalid');
  $(wrongEmailFormatText).css('visibility', 'hidden');
}

export function inputValidationError(
  inputSelector,
  spanSelector,
  validationText
) {
  $(inputSelector).addClass('invalid');
  $(spanSelector).text(validationText);
  $(spanSelector).css('visibility', 'visible');
}

export function controlEmailValidation(
  wrongEmailFormatText,
  isEmailValid,
  inputSelector
) {
  if (isEmailValid) {
    hideEmailValidation(wrongEmailFormatText);
  } else {
    inputValidationError(
      inputSelector,
      wrongEmailFormatText,
      emailWrongFormatErrorMessage
    );
  }
}

export function controlSubmitButton(buttonSelector, disabled) {
  $(buttonSelector).attr('disabled', disabled);
}

function checkUppercase(str) {
  if (!str) {
    changeAlertColor('password-uppercase', grey);
    passwordValidationRules.uppercaseValidated = false;
  }
  for (let i = 0; i < str.length; i++) {
    if (
      str.charAt(i) === str.charAt(i).toUpperCase() &&
      str.charAt(i).match(/[a-z]/i)
    ) {
      changeAlertColor('password-uppercase', green);
      passwordValidationRules.uppercaseValidated = true;
      return;
    } else {
      changeAlertColor('password-uppercase', grey);
      passwordValidationRules.uppercaseValidated = false;
    }
  }
}

function checkLength(str) {
  if (str.length >= 8) {
    changeAlertColor('password-length', green);
    passwordValidationRules.lenghtValidated = true;
  } else {
    changeAlertColor('password-length', grey);
    passwordValidationRules.lenghtValidated = false;
  }
}

function checkLowercase(str) {
  if (str.toUpperCase() !== str) {
    changeAlertColor('password-lowercase', green);
    passwordValidationRules.lowercaseValidated = true;
  } else {
    changeAlertColor('password-lowercase', grey);
    passwordValidationRules.lowercaseValidated = false;
  }
}

function checkDigit(str) {
  if (/\d/.test(str)) {
    changeAlertColor('password-digit', green);
    passwordValidationRules.digitValidated = true;
  } else {
    changeAlertColor('password-digit', grey);
    passwordValidationRules.digitValidated = false;
  }
}

function checkSpecialCharacter(str) {
  if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str)) {
    changeAlertColor('password-special-char', green);
    passwordValidationRules.specialCharacterValidated = true;
  } else {
    changeAlertColor('password-special-char', grey);
    passwordValidationRules.specialCharacterValidated = false;
  }
}

function changeAlertColor(str, color) {
  $('span#' + str).css('color', color);
}

export function checkValidationRules(str) {
  checkLength(str);
  checkUppercase(str);
  checkLowercase(str);
  checkDigit(str);
  checkSpecialCharacter(str);
}

export function redForNotValid() {
  if (passwordValidationRules.uppercaseValidated === false) {
    changeAlertColor('password-uppercase', red);
  }
  if (passwordValidationRules.lenghtValidated === false) {
    changeAlertColor('password-length', red);
  }
  if (passwordValidationRules.lowercaseValidated === false) {
    changeAlertColor('password-lowercase', red);
  }
  if (passwordValidationRules.digitValidated === false) {
    changeAlertColor('password-digit', red);
  }
  if (passwordValidationRules.specialCharacterValidated === false) {
    changeAlertColor('password-special-char', red);
  }
}

export function checkValidation() {
  return Object.values(passwordValidationRules).every(
    (value) => value === true
  );
}

export function invalidPasswordConfirmation(
  passwordConfirmationInput,
  passwordsNotMatchingText
) {
  $(passwordsNotMatchingText).css('visibility', 'visible');
  $(passwordConfirmationInput).addClass('not-valid');
}
