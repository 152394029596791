export function setupCSRFToken() {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  axios.defaults.headers.common.Accept = 'application/json';
}

export function displayValidationError(message, wrapper, validationError) {
  if (!$(wrapper).find('#' + validationError).length) {
    $(wrapper).append('<p id=' + validationError + '>' + message + '</p>');
  }
}
