import {
  checkValidationRules,
  redForNotValid,
  checkValidation,
  invalidPasswordConfirmation,
} from '../packs/validation_handler';

let isPasswordValid = false;

function checkPasswordMatch(
  passwordInput,
  passwordConfirmationInput,
  saveButton,
  passwordsNotMatchingText
) {
  $('.password-input').on('input', function () {
    if (
      $(passwordInput).val() === $(passwordConfirmationInput).val() &&
      checkValidation()
    ) {
      clearPasswordConfirmationStatus(
        saveButton,
        passwordConfirmationInput,
        passwordsNotMatchingText
      );
      $(passwordConfirmationInput).addClass('validated');
      $(saveButton).prop('disabled', false);
      isPasswordValid = true;
    } else {
      clearPasswordConfirmationStatus(
        saveButton,
        passwordConfirmationInput,
        passwordsNotMatchingText
      );
      $(passwordConfirmationInput).on('focusout', function () {
        if (
          $(passwordInput).val() !== $(passwordConfirmationInput).val() &&
          checkValidation()
        ) {
          invalidPasswordConfirmation(
            passwordConfirmationInput,
            passwordsNotMatchingText
          );
        }
      });
      clearPasswordConfirmationStatus(
        saveButton,
        passwordConfirmationInput,
        passwordsNotMatchingText
      );
    }
  });
}

function clearPasswordConfirmationStatus(
  saveButton,
  passwordConfirmationInput,
  passwordsNotMatchingText
) {
  $(passwordConfirmationInput).removeClass('not-valid');
  $(passwordConfirmationInput).removeClass('validated');
  $(passwordsNotMatchingText).css('visibility', 'hidden');
  $(saveButton).prop('disabled', true);
  isPasswordValid = false;
}

function passwordValidation(passwordInput, passwordConfirmationInput) {
  return (
    !$(passwordInput).val() ||
    !$(passwordConfirmationInput).val() ||
    isPasswordValid
  );
}

function userDataValidation(name, email) {
  return (
    name !== $('input#user_full_name').val() ||
    email !== $('input#user_email').val()
  );
}

function allowSavingChanges(
  saveButton,
  passwordInput,
  passwordConfirmationInput
) {
  const name = $('input#user_full_name').val();
  const email = $('input#user_email').val();
  $('input#user_full_name, input#user_email').on('input', function () {
    if (
      userDataValidation(name, email) &&
      passwordValidation(passwordInput, passwordConfirmationInput)
    ) {
      $(saveButton).prop('disabled', false);
    } else if (!isPasswordValid) {
      $(saveButton).prop('disabled', true);
    }
  });
}

window.passwordConfirmation = function (
  saveButton,
  passwordInput,
  passwordConfirmationInput,
  passwordsNotMatchingText
) {
  $(passwordInput).addClass('password-input');
  $(passwordConfirmationInput).addClass('password-input');
  allowSavingChanges(saveButton, passwordInput, passwordConfirmationInput);
  $(passwordInput).on('input', function () {
    const password = $(passwordInput)[0].value;
    checkValidationRules(password);
    if (checkValidation()) {
      $(passwordInput).addClass('validated');
      checkPasswordMatch(
        passwordInput,
        passwordConfirmationInput,
        saveButton,
        passwordsNotMatchingText
      );
    } else {
      $(passwordInput).removeClass('validated');
      clearPasswordConfirmationStatus(
        saveButton,
        passwordConfirmationInput,
        passwordsNotMatchingText
      );
    }
    $(passwordInput).on('focusout', function () {
      if (!checkValidation()) {
        $(passwordInput).addClass('not-valid');
        clearPasswordConfirmationStatus(
          saveButton,
          passwordConfirmationInput,
          passwordsNotMatchingText
        );
        redForNotValid();
      }
    });
    $(passwordInput).removeClass('not-valid');
    clearPasswordConfirmationStatus(
      saveButton,
      passwordConfirmationInput,
      passwordsNotMatchingText
    );
  });
};
