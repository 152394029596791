window.poster_upload = function (allowedFileExtension) {
  $('#production_poster').hover(
    function () {
      $('#poster-preview').css('filter', 'brightness(50%)');
    },
    function () {
      $('#poster-preview').css('filter', 'brightness(100%)');
    }
  );

  $('#production_poster').on('dragenter', function () {
    $('#poster-preview').css('filter', 'brightness(50%)');
  });

  $('#production_poster').on('dragleave', function () {
    $('#poster-preview').css('filter', 'brightness(100%)');
  });

  $('#production_poster').on('change', function () {
    if ($('#production_poster')[0].files[0]) {
      const uploadedFileExtension = $('#production_poster')[0]
        .files[0].name.split('.')
        .pop();
      if (!allowedFileExtension.includes(uploadedFileExtension)) {
        $('.wrong-format-alert').show();
      } else {
        $('.wrong-format-alert').hide();
        const reader = new FileReader();
        reader.onload = function (e) {
          $('#poster-preview').attr('src', e.target.result);
        };
        reader.readAsDataURL($('#production_poster')[0].files[0]);
      }
    }
  });
};
