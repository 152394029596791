function convertToUserTimezone(dateTimeDivId) {
  const utcDate = $(dateTimeDivId).text();
  const localTime = dayjs(utcDate);
  const userTimezone = dayjs.tz.guess();
  const formatted = localTime.toDate().toLocaleString(navigator.language);
  $(dateTimeDivId).text(formatted);
}

export function formatMultipleDates() {
  $("*:visible[class*='utc-date']").each(function () {
    convertToUserTimezone(this);
  });
}

$(document).ready(function () {
  formatMultipleDates();
});
