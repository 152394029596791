/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'bootstrap';
import 'jquery';
import axios from 'axios';

import Rails from '@rails/ujs';
import './poster_preview';
import './change_watermark_text_size';
import './organisation_edition';
import './password_validation';
import './toggle_password';
import './login_page_validation';
import './disable_copy';
import './application_handler';
import './convert_to_timezone';

import '../fonts/Roboto-Regular.ttf';
import '../fonts/Roboto-Bold.ttf';

require('../src/application');
require.context('../images', true, /\.(gif|jpg|png|svg)$/i);

global.axios = require('axios');
global.$ = require('jquery');
global.dayjs = require('dayjs');
global.axiosRequestsHandler = require('./configure_axios');

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

axiosRequestsHandler.setupCSRFToken();

Rails.start();
